import { Capacitor } from "@capacitor/core";
import { createTheme, useTheme } from "@mui/material/styles";
// fonts
import OpenSans_600_Woff2 from "./fonts/OpenSans-600.woff2";
import OpenSans_600_Woff from "./fonts/OpenSans-600.woff";
import OpenSans_600_Italic_Woff2 from "./fonts/OpenSans-600-italic.woff2";
import OpenSans_600_Italic_Woff from "./fonts/OpenSans-600-italic.woff";
import OpenSans_700_Woff2 from "./fonts/OpenSans-700.woff2";
import OpenSans_700_Woff from "./fonts/OpenSans-700.woff";
import OpenSans_700_Italic_Woff2 from "./fonts/OpenSans-700-italic.woff2";
import OpenSans_700_Italic_Woff from "./fonts/OpenSans-700-italic.woff";
// types
import type { ThemeOptions } from "@mui/material/styles";

// import all images
const imageCache = new Map<string, string>();

export const importAll = (requireContextTheme: __WebpackModuleApi.RequireContext, requireContextCore: __WebpackModuleApi.RequireContext) => {
	for (const key of requireContextTheme.keys()) {
		imageCache.set(key, requireContextTheme(key));
	}
	for (const key of requireContextCore.keys()) {
		if (!imageCache.has(key)) {
			imageCache.set(key, requireContextCore(key));
		}
	}
};

const { breakpoints } = createTheme();

export const themeOptions = {
	typography: {
		fontFamily: "OpenSans",
		h1: {
			fontSize: "22px",
			[breakpoints.only("xs")]: {
				fontSize: "18px",
			},
		},
		h4: {
			fontSize: "22px",
		},
		h6: {
			fontSize: "22px",
		},
		body1: {
			fontSize: "16px",
		},
		body2: {
			fontSize: "14px",
		},
		subtitle2: {
			fontSize: "14px",
		},
	},
	shape: {
		borderRadius: 12,
	},
	components: {
		MuiAlert: {
			defaultProps: {
				variant: "filled",
			},
		},
		MuiCardActionArea: {
			defaultProps: {
				draggable: Capacitor.isNativePlatform() ? "false" : undefined,
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					"&:last-child": {
						paddingBottom: "16px",
					},
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
				@font-face {
					font-family: "OpenSans";
					font-style: normal;
					font-weight: 400 600;
					src: url(${OpenSans_600_Woff2 as string}) format("woff2"), url(${OpenSans_600_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: italic;
					font-weight: 400 600;
					src: url(${OpenSans_600_Italic_Woff2 as string}) format("woff2"), url(${OpenSans_600_Italic_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: normal;
					font-weight: 700;
					src: url(${OpenSans_700_Woff2 as string}) format("woff2"), url(${OpenSans_700_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: italic;
					font-weight: 700;
					src: url(${OpenSans_700_Italic_Woff2 as string}) format("woff2"), url(${OpenSans_700_Italic_Woff as string}) format("woff");
				}
			`,
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					top: "env(safe-area-inset-top)",
					bottom: "env(safe-area-inset-bottom)",
					left: "env(safe-area-inset-left)",
					right: "env(safe-area-inset-right)",
				},
			},
		},
		MuiFab: {
			defaultProps: {
				color: "primary",
			},
			styleOverrides: {
				root: {
					zIndex: "initial",
				},
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: "standard",
			},
		},
		MuiIconButton: {
			defaultProps: {
				size: "large",
			},
		},
		MuiLinearProgress: {
			defaultProps: {
				variant: "determinate",
			},
		},
		MuiLink: {
			defaultProps: {
				draggable: Capacitor.isNativePlatform() ? "false" : undefined,
			},
		},
		MuiListItemButton: {
			defaultProps: {
				draggable: Capacitor.isNativePlatform() ? "false" : undefined,
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "inherit",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "unset",
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: "standard",
			},
		},
		MuiSkeleton: {
			defaultProps: {
				animation: "wave",
			},
			styleOverrides: {
				text: {
					maxWidth: "100%",
					borderRadius: "4px",
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "center",
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				allowScrollButtonsMobile: true,
				variant: "fullWidth",
			},
			styleOverrides: {
				indicator: {
					display: "none",
				},
			},
		},
		// MuiTab: {
		// 	styleOverrides: {
		// 		root: {
		// 			"color": defaultTheme.palette.primary.main,
		// 			"&.Mui-selected": {
		// 				color: defaultTheme.palette.primary.contrastText,
		// 				backgroundColor: defaultTheme.palette.primary.main
		// 			}
		// 		}
		// 	}
		// },
		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 600,
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: "standard",
				autoComplete: "off",
				inputProps: {
					spellCheck: "false",
					autoCorrect: "off",
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				enterTouchDelay: 400,
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					overflowWrap: "anywhere",
				},
			},
		},
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
	},
} as const satisfies ThemeOptions;

// resolve relative image path to image url
// ex: images("devices/di_camera_a.svg")
export const images = (path: string): string | undefined => {
	const theme = useTheme();

	// ignore console errors when running tests
	if (process.env.NODE_ENV === "test") {
		return "";
	}

	const themeStyle = (theme.palette.mode === "light") ? "generic" : theme.palette.mode;
	const fullPath = imageCache.has(`./${themeStyle}/${path}`) ? `./${themeStyle}/${path}` : `./generic/${path}`;
	const image = imageCache.get(fullPath);
	if (image === undefined) {
		console.error(`could not find image ${path}`);
		// throw new Error(`could not find image ${path}`);
	}
	return image;
};

// TODO: remove
export const hasImage = (path: string): boolean => {
	const theme = useTheme();

	const themeStyle = (theme.palette.mode === "light") ? "generic" : theme.palette.mode;
	return imageCache.has(`./${themeStyle}/${path}`) || imageCache.has(`./generic/${path}`);
};
